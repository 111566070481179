//@ts-nocheck
export const appConfiguration = {
    API: {
        URL: window.API_URL || ""
    },
    LocalLogin: {
        active: window.LocalLogin || false
    },
    AUTH: {
        CLIENT_ID: window.CLIENT_ID || "",
        AUTHORITY: window.AUTHORITY || "",
        REDIRECT_URI: window.REDIRECT_URI || "",
        SCOPES: window.SCOPES || ""
    }
}
export default appConfiguration;